<template>
  <div class="content">
    <div class="title font-black">Please enter your purchase requirement</div>
    <div class="body">
      <div class="form">
        <Form ref="form" :model="inquireForm" class="register-form">
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Product Name"
                :rules="required"
                prop="product_name"
              >
                <Input v-model="inquireForm.product_name" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem label="Quantity" :rules="required" prop="quantity">
                <Input v-model="inquireForm.quantity" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Quality Standard"
                :rules="required"
                prop="quality_standard"
              >
                <Input v-model="inquireForm.quality_standard" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Application"
                :rules="required"
                prop="application"
              >
                <Input v-model="inquireForm.application" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem label="Main Specs" :rules="required" prop="main_specs">
                <Input v-model="inquireForm.main_specs" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem label="Price Basis">
                <Select
                  filterable
                  v-model="inquireForm.price_basis"
                  @on-change="selectPrice($event)"
                >
                  <Option v-for="item in prices" :value="item" :key="item">{{
                    item
                  }}</Option>
                </Select>
              </FormItem>
            </i-col>
            <i-col span="24" class="price" v-show="'' != price_type">
              <FormItem
                label="Address"
                v-if="inquireForm.price_basis == 'Others'"
              >
                <Input
                  v-model="inquireForm.price_basis_value"
                  placeholder="City, State and Country"
                />
              </FormItem>
              <FormItem label="Price" v-else>
                <Input v-model="inquireForm.price_basis_value" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem label="Package" :rules="required" prop="packaging">
                <Input v-model="inquireForm.packaging" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Shipment Date"
                :rules="required"
                prop="shipment_date"
              >
                <DatePicker
                  type="date"
                  v-model="inquireForm.shipment_date"
                  format="yyyy-MM-dd"
                  placeholder="Please select a date"
                  style="display: block"
                ></DatePicker>
              </FormItem>
            </i-col>
            <i-col span="24">
              <FormItem label="Price Idea" prop="price_idea">
                <Input v-model="inquireForm.price_idea" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Required Documents"
                :rules="required"
                prop="required_documents"
              >
                <Input v-model="inquireForm.required_documents" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem label="Note" prop="note" :class="inputNote">
                <Input
                  v-model="inquireForm.note"
                  type="textarea"
                  placeholder=""
                  :rows="4"
                  :class="inputNote"
                  style="width: 100%;height:100px"
                />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="20">
            <i-col span="24">
              <FormItem
                label="Expire Date"
                :rules="required"
                prop="expire_date"
              >
                <DatePicker
                  type="date"
                  v-model="inquireForm.expire_date"
                  format="yyyy-MM-dd"
                  placeholder="Please select a date"
                  style="display: block"
                ></DatePicker>
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </div>
      <div
        v-if="!mloading"
        class="btn font-medium"
        @click="handleSubmit('form')"
      >
        Submit
      </div>
      <div v-else class="btn font-medium">
        Sending...
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _createSourcing } = api;
export default {
  name: "sourcing-inquiry-attendee",
  computed: {},
  components: {},
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      inquireForm: {},
      inputNote: "input-note",
      product_name: "",
      quantity: "",
      quantity_standard: "",
      mloading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: ""
    };
  },
  // created:{
  //
  // },
  mounted() {
    this.inquireForm.product_name = this.$route.query.first;
    this.inquireForm.quantity = this.$route.query.second;
    this.inquireForm.quality_standard = this.$route.query.third;
    this.inquireForm.application = this.$route.query.d;
  },
  methods: {
    selectPrice(e) {
      this.price_type = e;
    },
    sendInquire() {
      this.mloading = true;
      _createSourcing(this.inquireForm)
        .then(() => {
          this.$Message.success("Add Sourcing Success");

          this.showInquire = false;
          this.handleReset("form");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.mloading = false;
        });
    },
    handleSubmit(name) {
      console.log(name);
      this.$refs[name].validate(valid => {
        if (valid) {
          this.sendInquire();
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
.homePage {
  background: #f4f4f4;
  margin-top: 10px !important;
}
.sc-width {
  margin: 0 auto;
}
.content {
  background: #ffffff;
  box-shadow: 0px 0px 0px 6px rgba(239, 132, 45, 0.3);
  border-radius: 5px;
  /*width: 100% !important;*/
  /*height: 1130px;*/
  margin: 0 5px 100px 5px !important;
  position: relative;
  top: 56px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.title {
  font-size: 20px;

  font-weight: 700;
  color: #ef7f21;
  line-height: 22px;
  text-align: center;
  padding-top: 20px;
}
.form {
  margin: 0 auto;
  .item {
    display: flex;
    justify-content: center;
  }
  .register-form {
    position: relative;
    /*left:10px !important;*/
    top: 20px;
  }
}
.btn {
  /*width: 290px;*/
  height: 40px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 20px;

  font-weight: 500;
  color: #fefefe;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  /*position: relative;*/
  /*top: 30px;*/
  /*left: 400px;*/
  cursor: pointer;
  margin: 30px auto;
  width: 200px;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-select-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
.upload {
  width: 100%;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px;
  cursor: pointer;
}

.upload-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.register-form >>> .ivu-input {
  /*width:300px;*/
  height: 36px;
  font-size: 16px;
}
.register-form >>> .ivu-input-number {
  /*width:300px !important;*/
  font-size: 16px;
}
.register-form >>> .ivu-select {
  /*width:300px;*/
  font-size: 16px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 14px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
  /*float: right !important;*/
  text-align: left !important;
}
.ivu-select-item {
  font-size: 16px !important;
}
.ivu-select-selected-value {
  font-size: 16px !important;
}
.ivu-row {
  margin-left: 10px;
  margin-right: 0px !important;
}
</style>
