<template>
  <div :class="['homePage', { homePageMobile: mobileWidth }]">
    <attendee v-if="!mobileWidth" />
    <attendeeMobile v-if="mobileWidth" />
  </div>
</template>

<script>
import attendee from "./sourcing-inquiry-attendee";
import attendeeMobile from "./sourcing-inquiry-attendee-mobile";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("user", ["token", "role"])
  },
  components: {
    attendee,
    attendeeMobile
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      inquireForm: {},
      inputNote: "input-note"
    };
  },
  // created:{
  //
  // },
  mounted() {},
  methods: {},
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
}
@media (max-width: 1400px) and (min-width: 1200px) {
}
@media (max-width: 1200px) {
}
.homePage {
  background: #f4f4f4;
  margin-top: 128px;
  height: 1250px;
}
.homePageMobile {
  background: #f4f4f4;
  margin-top: 10px;
  height: auto !important;
}
.sc-width {
  margin: 0 auto;
}
.router-transitions {
  opacity: 1;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-select-input {
  /*width:400px;*/
  height: 36px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
body.app-loading .router-transitions {
  opacity: 1;
}
</style>
